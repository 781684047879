import React from 'react';
import {AnchorButton, Card} from "@blueprintjs/core";


export const InstaHeader = React.memo((props) => {
    return (
        <Card className={'info-block'} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <h3 style={{textAlign: 'center', marginRight: '10px'}}>Наш Instagram: <a href='https://www.instagram.com/avtokey_sumy/' style={{color: 'bisque'}}>@avtokey_sumy</a>
            </h3>
            <AnchorButton icon={'draw'} text={'Написати'} href='https://www.instagram.com/avtokey_sumy/'/>
        </Card>
    );
})


 