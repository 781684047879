import ImageGallery from 'react-image-gallery';
import React from "react";
import asset_1 from './assets/1.jpg'
import asset_2 from './assets/2.jpg'
import asset_3 from './assets/3.jpg'
import asset_4 from './assets/4.jpg'
import asset_5 from './assets/5.jpg'
import asset_6 from './assets/6.jpg'
import asset_7 from './assets/7.jpg'
import asset_8 from './assets/8.jpg'
import asset_9 from './assets/9.jpg'
import asset_10 from './assets/10.jpg'

const images = [
    {
        original: asset_1,
        thumbnail: asset_1,
    },
    {
        original: asset_2,
        thumbnail: asset_2,
    },
    {
        original: asset_3,
        thumbnail: asset_3,
    },
    {
        original: asset_4,
        thumbnail: asset_4,
    },
    {
        original: asset_5,
        thumbnail: asset_5,
    },
    {
        original: asset_6,
        thumbnail: asset_6,
    },
    {
        original: asset_7,
        thumbnail: asset_7,
    },
    {
        original: asset_8,
        thumbnail: asset_8,
    },
    {
        original: asset_9,
        thumbnail: asset_9,
    },
    {
        original: asset_10,
        thumbnail: asset_10,
    }
];

export class Gallery extends React.Component {
    render() {
        return <div className={'info-block'}>
            <ImageGallery
                items={images}
                showThumbnails={true}
                showPlayButton={false}
            />
        </div>
    }
}