import React from 'react';
import {AnchorButton, Card} from "@blueprintjs/core";

export const NumberHeader = React.memo((props) => {
    return (
        <Card className={'info-block'} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <h3 style={{textAlign: 'center', marginRight: '10px'}}>Наш телефон: <a href='tel:+380990436341' style={{color: 'bisque'}}>+380990436341</a></h3>
            <AnchorButton icon={'phone'} text={'Подзвонити'} href='tel:+380990436341'/>
        </Card>
    );
})